import { render, staticRenderFns } from "./TechUsed.vue?vue&type=template&id=29f6c76e&scoped=true&"
import script from "./TechUsed.vue?vue&type=script&lang=js&"
export * from "./TechUsed.vue?vue&type=script&lang=js&"
import style0 from "./TechUsed.vue?vue&type=style&index=0&id=29f6c76e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29f6c76e",
  null
  
)

export default component.exports